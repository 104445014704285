
import {Component, Emit, Vue} from 'vue-property-decorator';
import DebounceInput from '@/components/DebounceInput.vue';
import IUsersListFilters from '@/types/IUsersListFilters';

@Component({
  name: 'UsersFilters',
  components: {DebounceInput},
})
export default class UsersFilters extends Vue {
  filters: IUsersListFilters = {
    mail: '',
  };

  @Emit('changed')
  changed() {
    return this.filters;
  }
}
