
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import IUser from '@/types/IUser';
import {DataTableHeader} from 'vuetify';
import AdminUsersService from '@/services/AdminUsersService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import {IPagination} from '@/types/IPagination';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

@Component({
  name: 'UsersList',
  components: {
    ConfirmationDialog,
    UserForm: () => import('@/components/AdminUsers/UserForm.vue'),
  },
})
export default class UsersList extends Vue {
  @Prop()
  data!: IUser[];

  @Prop()
  loading!: boolean;

  @Prop()
  pagination!: IPagination;

  changeUserStatusLoading: boolean = false;
  changeUserStatus: IUser | null = null;
  editUser: IUser | null = null;
  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_ID',
      value: 'id',
      sortable: false,
    },
    {
      text: 'SYSTEM_FIRST_NAME',
      value: 'firstName',
      sortable: false,
    },
    {
      text: 'SYSTEM_LAST_NAME',
      value: 'lastName',
      sortable: false,
    },
    {
      text: 'SYSTEM_EMAIL',
      value: 'mail',
      sortable: false,
    },
    {
      text: 'SYSTEM_COMPANY',
      value: 'companyName',
      sortable: false,
    },
    {
      text: 'SYSTEM_STATUS',
      value: 'active',
      sortable: false,
    },
    {
      text: 'SYSTEM_ACTION',
      value: 'actions',
      sortable: false,
      align: 'end',
    },
  ];

  optionsUpdated(options: { page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[] }) {
    this.pagination.page = options.page;
    this.pagination.size = options.itemsPerPage;
    this.pagination.sortBy = options.sortBy[0];
    this.pagination.sortDirection = options.sortDesc[0] ? 'DESC' : 'ASC';

    this.refreshData();
  }

  openConfirmationDialog(item: IUser) {
    this.changeUserStatus = {...item};
  }

  closeChangeUserStatus() {
    this.changeUserStatusLoading = false;
    this.changeUserStatus = null;
  }

  edit(userId: number | string) {
    const candidate = this.data.find((item) => item.id === userId);
    if (candidate) {
      this.editUser = candidate;
    }
  }

  @Emit('fetchData')
  refreshData() {
    return;
  }

  onEdited() {
    this.editUser = null;
    this.refreshData();
  }

  async toggleStatus() {
    try {
      if (this.changeUserStatus) {
        this.changeUserStatusLoading = true;
        await AdminUsersService.toggleStatus(this.changeUserStatus.id, !this.changeUserStatus.active);
        this.closeChangeUserStatus();
        this.refreshData();
        notify(NotificationTypes.success, this.$t('SYSTEM_STATUS_HAS_BEEN_CHANGED'));
      }
    } catch (e) {
      resolveError(e, 'change_status');
    } finally {
      this.changeUserStatusLoading = false;
    }
  }
}
